@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ensure this is after Tailwind imports */
html, body {
  scroll-behavior: smooth;
  scroll-padding-top: 50px; /* Adjust this based on your navbar height */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}
